.solesta-dropdown {
    button {
        background: linear-gradient(to right, #f9cb0a, #f18e1f);
        border-width: 0;
    }
}

.dropdown-menu.show {
    max-height: 80vh;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}
