#admin-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    background-color: #f5f7f9;
}

#table-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1vh auto;
    width: 99%;
    padding: 1.5vh 20px;
}

.toolbar-input {
    width: 150px;
}

.tab-overview {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

/*********** TABLE STYLES **********/
.table-root {
    flex-grow: 1;
    margin: 0 auto 1vh;
    width: 99%;
    max-height: 80vh;
}

.table-container {
    height: calc(100% - 52px);
}

.table-actions {
    text-align: center !important;
}

.table-cell {
    min-width: 170;
    font-weight: bold !important;
}

tr, td {
    height: 57px;
}