#small-banner-main {
    width: 100%;
    background-color: rgba(10, 60, 73, 0.8);
    padding: 1.5vh 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#small-banner-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
        width: 250px;
    }
}

#small-banner-menu-items {
    margin-left: 30px;
}

.menu-item {
    margin: 0 17px;
    &:hover {
        cursor: pointer;
    }
}

.menu-item .menu-text-selected::after {
    top: 100%;
    height: 2px;
    width: 100% !important;
    left: 0%;
    background-color: rgba(255, 255, 255, 0.6);
}

.menu-item .menu-text::after {
    top: 100%;
    height: 2px;
    width: 0%;
    left: 0%;
    background-color: rgba(255, 255, 255, 0.6);
      transition: 0.5s ease all .1s;
  }
  
  .menu-item:hover .menu-text::after {
    width: 100%;
    transition: 0.3s ease all;
  }

  .menu-text {
    position: relative;
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
  }
  
  .normal-underline,
  .normal-underline:hover {
    color: #444;
    text-decoration: underline;
  }
  
  .menu-text,
  .menu-text:hover,
  .menu-text:focus,
  .menu-text:active {
    color: #fff;
    text-decoration: none;
  }
  
  .menu-text::after {
    content: "";
    position: absolute;
  }