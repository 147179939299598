@import '../../../../assets/Variables/variables';

.MuiTypography-body1 {
    font-family: $portal-fonts !important;
    font-weight: 400 !important;
    font-size: 2.2rem !important;
    color: white;
}

.full-page {
    width: 100vw;
    height: 100vh;
    background-image: url("./../../../../assets/img/portaal_achtergrond.jpg");
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
}

.logo-triangle {
    width: 0;
    height: 0;
    border-top: 50vh solid #00353e;
    border-right: 50vw solid transparent;
}

.ui-triangle {
    width: 0;
    height: 0;
    margin-top: -50vh;
    border-bottom: 100vh solid #00353e;
    border-left: 100vw solid transparent;
}

.logo-container {
    position: absolute;
    top: 0;
    left: 0;
}

.portal-logo {
    width: 28vw;
    margin-top: 2vh;
}

.ui-container {
    position: absolute;
    bottom: 5vh;
    right: 25vw;
}

.boiler-status-info {
    text-align: center;
}

.inline-items {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.temp-buffer {
    margin-top: 20px;
}

@media only screen and (max-width: 1500px) {
    .logo-triangle {
        border-right: none;
        border-top: none;
        position: absolute;
        background-color: #00353e;
        top: 0;
        left: 0;
        width: 100vw;
        height: 20vh;
    }

    .ui-triangle {
        border-bottom: none;
        border-left: none;
        position: absolute;
        background-color: #00353e;
        bottom: 0;
        left: 0;
        width: 100vw;
        height: 40vh;
    }

    .logo-container {
        width: 100vw;
        display: flex;
        justify-content: center;
    }
    
    .portal-logo {
        width: 90%;
        max-width: 600px;
        min-width: 500px;
        margin-top: 2vh;
    }
    
    .ui-container {
        width: 100%;
        display: flex;
        justify-content: center;
        right: unset;
    }

}

@media only screen and (max-width: 1500px) {

    .MuiTypography-body1 {
        font-size: 1.6rem !important;
    }
}